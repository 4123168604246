import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { ExploreButton } from 'components/_universal/ExploreButton';
import { navigate } from 'gatsby';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H3 } from 'styles/Typography.styled';
import { drawTextWithLineBreaks } from 'utils/strings';
import { SPACING_PX } from 'styles/theme';
import offerData from 'shared/data/Offer.data';

export const OfferText = styled(Common.Div)(
  ({ theme: { fontSizes, fontWeight } }) => css`
    font-size: ${fontSizes.b3};
    font-weight: ${fontWeight.light};
    line-height: 25px;
    max-width: 360px;
  `
);

export const VerticalLine = styled(Common.Div)(
  () =>
    css`
      border-right: 1px solid white;
      height: 100%;
      max-height: 338px;
      margin-left: 18px;

      ${mediaQueries.md} {
        height: auto;
      }
    `
);

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 144px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  justify-items: center;
  margin: ${8 * SPACING_PX}px 0;

  @media (max-width: 740px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  margin-left: 18px;
  border-left: 1px solid ${({ theme: { colors } }) => colors.white};
`;

export const ExploreOurOfferSection = () => {
  return (
    <Section title="explore our offer" variant="secondary">
      <Wrapper>
        {offerData.map(([title, sub, icon, label, route], idx) => (
          <Common.Div key={idx}>
            <Common.Div flex="row" gap="16px" alignItems="center" mb={3}>
              <Common.Svg src={icon} />
              <H3>{label}</H3>
            </Common.Div>

            <Common.Div flex="row" gap="16px">
              <Item>
                <OfferText mt={2} mb={4}>
                  {drawTextWithLineBreaks(title)}
                </OfferText>
                <OfferText>{drawTextWithLineBreaks(sub)}</OfferText>
                <ExploreButton onClick={() => navigate(route)} />
              </Item>
            </Common.Div>
          </Common.Div>
        ))}
      </Wrapper>
    </Section>
  );
};
