import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import { B2, B3 } from 'styles/Typography.styled';
import 'swiper/css/grid';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { drawTextWithLineBreaks } from 'utils/strings';
import { ROUTE_SOFTWARE } from 'shared/paths';

export const StepContainer = styled(Common.Div)(
  () =>
    css`
      border-left: 1px solid white;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `
);

export const StepHead = styled(Common.Div)(
  ({ theme: { colors, spacing } }) =>
    css`
      background: ${colors.section};
      display: flex;
      align-items: center;
      padding: ${spacing(3)};
      height: 78px;
    `
);

export const StepBody = styled(Common.Div)(
  ({ theme: { spacing }, isMobile }) =>
    css`
      padding: ${spacing(3)};
      ${isMobile &&
      css`
        min-height: 222px;
      `};
    `
);

export const StepGradientHead = styled(Common.Div)(
  ({ theme: { gradients, spacing } }) =>
    css`
      background: ${gradients.teal};
      display: flex;
      align-items: center;
      padding: ${spacing(3)};
      height: 78px;
    `
);

export const HowWeWorkText = styled(Common.Div)(
  ({ theme: { fontSizes } }) =>
    css`
      font-size: ${fontSizes.b3};
      line-height: 25px;
    `
);

const TEXT_CONTACT_US = `Contact us and\n lets have a call\n  or meeting.`;
const TEXT_MEETING = `Discussion about the\n project, scope,\n technology and\n requirements.`;
const TEXT_ONBOARDING = `Kickoff workshops and\n meetings will help us to\n refine the scope.`;
const TEXT_POC = `Altogether, having POC\n we validate your\n business hypothesis\n with the market and\n verify the cooperation\n process.`;
const TEXT_COOPERATION = `We will help you grow your\n business by developing software\n and hardware products that meet\n the requirements of upcoming\n technology market changes.`;

export const HowWeWorkSection = () => {
  const isMd = useBreakpoint('md');
  return (
    <Section title="how we work" variant="secondary">
      <Common.Div flex={isMd ? 'column' : 'row'} mt={8} mb={8}>
        <StepContainer>
          <StepHead>
            <B3>Contact Us</B3>
          </StepHead>
          <StepBody>
            <HowWeWorkText>{drawTextWithLineBreaks(TEXT_CONTACT_US)}</HowWeWorkText>
          </StepBody>
        </StepContainer>
        <StepContainer>
          <StepHead>
            <B3>Meeting or call</B3>
          </StepHead>
          <StepBody>
            <HowWeWorkText>{drawTextWithLineBreaks(TEXT_MEETING)}</HowWeWorkText>
          </StepBody>
        </StepContainer>
        <StepContainer>
          <StepHead>
            <B3>Onboarding</B3>
          </StepHead>
          <StepBody>
            <HowWeWorkText>{drawTextWithLineBreaks(TEXT_ONBOARDING)}</HowWeWorkText>
          </StepBody>
        </StepContainer>
        <StepContainer>
          <StepHead>
            <B3>PoC/Prototype</B3>
          </StepHead>
          <StepBody>
            <HowWeWorkText>{drawTextWithLineBreaks(TEXT_POC)}</HowWeWorkText>
          </StepBody>
        </StepContainer>
        <StepContainer>
          <StepGradientHead>
            <B3>Long term cooperation</B3>
          </StepGradientHead>
          <StepBody>
            <HowWeWorkText>{drawTextWithLineBreaks(TEXT_COOPERATION)}</HowWeWorkText>
          </StepBody>
        </StepContainer>
      </Common.Div>
      <Common.Div flex="row" alignItems="center" justifyContent="flex-end" onClick={() => navigate(ROUTE_SOFTWARE)}>
        <B2 align="right" mr={1}>
          more
        </B2>
        <img src={ArrowSvg} alt="exploreButton" />
      </Common.Div>
    </Section>
  );
};
