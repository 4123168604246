import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { B2 } from 'styles/Typography.styled';
import styled from 'styled-components';

export const ExploreButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledButton mt={5} flex="row" alignItems="center" onClick={onClick}>
      <B2 align="right" mr={1}>
        explore
      </B2>
      <img src={ArrowSvg} alt="exploreButton" />
    </StyledButton>
  );
};

const StyledButton = styled(Common.Div)`
  img {
    transition: 120ms ease transform;
  }

  &:hover {
    img {
      transform: translateX(8px);
    }
  }
`;
